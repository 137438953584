import React from "react";
import PropTypes from "prop-types";

import { LIBRARY } from "@decathlon/patterns";

const PageRender = ({ page }) => {
  const widgets = page.widgets.filter((w) => Boolean(LIBRARY[w.component]));

  return (
    <div className="decathlon-patterns">
      {widgets.map((widget, idx) =>
        React.createElement(
          LIBRARY[widget.component],
          Object.assign({}, { key: idx }, widget.settings)
        )
      )}
    </div>
  );
};

PageRender.propTypes = {
  page: PropTypes.object.isRequired,
};

export default PageRender;
